import React from "react"
import Safe from "react-safe"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { RedocStandalone } from "redoc"

const APIReference = () => {
  const queryResult = useStaticQuery(graphql`
    {
      file(ext: { eq: ".json" }, name: { eq: "daiquiri.openapi" }) {
        publicURL
      }
    }
  `)

  const redocJson = queryResult.file.publicURL

  return (
    <Layout>
      <SEO title="API Reference" />
             <Safe.style>{`.redoc-wrap .menu-content .scrollbar-container > div[class*="sc"] { display: none;} /*!sc*/
   html {scroll-behavior: auto !important} .sc-Gvgqn.emDDSd:before{display:unset!important;content:''!important;} .sc-bXevSJ.NGewh{margin-left:0px !important;}`}</Safe.style>
      <div className="">
        <RedocStandalone
          specUrl={redocJson}
          options={{ theme: { colors: { primary: { main: "#1D71B8" } } }, nativeScrollbars: true, hideLoading: true }}
        />
      </div>
    </Layout>
  )
}

export default APIReference